export const domain = `https://${process.env.NEXT_PUBLIC_WWW_DOMAIN}`;

export const openGraph = {
  creditCard: {
    images: [
      {
        url: `${domain}/images/credit-card/seo/website-thumb-large.jpg`,
        width: 1200,
        height: 630,
      },
      {
        url: `${domain}/images/credit-card/seo/website-thumb.jpg`,
        width: 300,
        height: 300,
      },
    ],
  },
  savings: {
    images: [
      {
        url: `${domain}/images/savings/seo/website-thumb-large.jpg`,
        width: 1200,
        height: 630,
      },
      {
        url: `${domain}/images/savings/seo/website-thumb.jpg`,
        width: 300,
        height: 300,
      },
    ],
  },
  default: {
    images: [
      {
        url: `${domain}/images/seo/website-thumb-large.jpg`,
        width: 1200,
        height: 630,
      },
      {
        url: `${domain}/images/seo/website-thumb.jpg`,
        width: 300,
        height: 300,
      },
    ],
  },
  mgm: {
    images: [
      {
        url: `${domain}/images/seo/website-mgm-thumb-large.jpg`,
        width: 1200,
        height: 630,
      },
      {
        url: `${domain}/images/seo/website-mgm-thumb-large.jpg`,
        width: 300,
        height: 300,
      },
    ],
  },
  experiment: {
    images: [
      {
        url: `${domain}/images/seo/open-graph-experiment-large.jpg`,
        width: 1200,
        height: 630,
      },
      {
        url: `${domain}/images/seo/open-graph-experiment.jpg`,
        width: 300,
        height: 300,
      },
    ],
  },
};

export const baseBreadcrumb = [{
  position: 1,
  name: 'Inicio',
  item: `${domain}/`,
}];

export const corporateContactData = {
  url: domain,
  logo: `${domain}/images/nu-icon.png?v=2`,
  contactPoint: [{
    telephone: '+57 601 357 1102',
    contactType: 'customer service',
    contactOption: 'TollFree',
    areaServed: 'CO',
    availableLanguage: ['Spanish'],
  }],
};
