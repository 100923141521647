import React from 'react';
import PropTypes from 'prop-types';

import { getEnableCucuta } from '../../utils/environmentUtils';

import FooterNavigation from './patterns/navigation/FooterNavigation';
import FooterCompany from './patterns/company/FooterCompany';
import FooterSocial from './patterns/social/FooterSocial';
import { StyledFooter } from './styles/StyledFooter';
import ComponentDefaultProps from './MultiProductFooterProps';

export const footerTypes = {
  FULL: 'FULL',
  NO_SOCIAL: 'NO_SOCIAL',
  ONLY_COMPANY: 'ONLY_COMPANY',
};

const footerLayouts = {
  FULL: {
    showSocial: true,
    showNavigation: true,
    showCompany: true,
  },
  NO_SOCIAL: {
    showSocial: false,
    showNavigation: true,
    showCompany: true,
  },
  ONLY_COMPANY: {
    showSocial: false,
    showNavigation: false,
    showCompany: true,
  },
  CUCUTA: {
    showSocial: true,
    showNavigation: true,
    showCompany: true,
  },
};

const MultiProductFooter = ({ hocOptions }) => {
  const {
    showSocial,
    showNavigation,
    showCompany,
  } = {
    ...footerLayouts.FULL,
    ...footerLayouts[hocOptions.footerType],
  };

  const defineProps = (getEnableCucuta === 'true') ? ComponentDefaultProps.cucuta : ComponentDefaultProps.multiproduct;
  const {
    navigation,
    social,
    company,
  } = defineProps[hocOptions.companyFooterType];

  let companyHocOptions = null;
  if (getEnableCucuta === 'true') {
    const cucutaHocOptions = { ...hocOptions };
    cucutaHocOptions.companyFooterType = 'CUCUTA';
    companyHocOptions = { ...company, ...cucutaHocOptions };
  } else {
    companyHocOptions = { ...company, ...hocOptions };
  }
  return (
    <StyledFooter>
      {showNavigation && <FooterNavigation {...navigation} />}
      {showSocial && <FooterSocial {...social} />}
      {showCompany && <FooterCompany {...companyHocOptions} />}
    </StyledFooter>
  );
};

MultiProductFooter.propTypes = {
  hocOptions: PropTypes.shape({
    companyFooterType: PropTypes.string.isRequired,
    footerType: PropTypes.string.isRequired,
  }),
};

MultiProductFooter.defaultProps = {
  hocOptions: {
    companyFooterType: 'FULL',
    footerType: 'FULL',
  },
};

export default MultiProductFooter;
